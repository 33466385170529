<div class="thank-you-page-header">
    <img class="bella-avatar" src="assets/bella/avatar-1.png">
    <div class="introduction-wrapper">
        <h1 id="introduction-text" class="typewriter-animation b2b-pink intro-text">
            Thanks for chatting with me!
        </h1>
    </div>
    <div class="content-wrapper" >
        <p class="typewriter-text"> 
            {{displayedReferralText}}
        </p>
    </div>
</div>

<div class="sign-up-wrapper" [@fadeIn]="finishedLoading ? 'visible' : 'hidden'">
   <!-- <a href="https://apps.apple.com/us/app/baby2body-pregnancy-wellness/id1108801085"><img id="app-store-icon" src="assets/app-store.png"></a> -->
   <a 
        class="b2b-btn-color sign-up-btn" 
        href="https://app.baby2body.com/auth/registration/" 
        target="_blank" 
        mat-fab extended 
    >
    <mat-icon>favorite</mat-icon>
    Sign up
   </a>
</div>