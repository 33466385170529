<div class="chat-wrapper">
    <div class="chat-top-section">
        <div class="chat-header">
            <div class="header-section">
                <div>
                    <img id="chat-avatar" src="assets/bella/avatar-1.png">
                </div>
                <div class="title-wrapper">
                    <div class="main-title">Ask Bella</div>
                    <div class="sub-title"><strong>Your Baby2Body Coach </strong> <em id="italic-text">powered by Bella</em></div>
                </div>
            </div>
            <button class="chat-settings" mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="handleNewChat()">
                    <mat-icon>add</mat-icon>
                    <span>New chat</span>
                </button>
            </mat-menu>
        </div>
        <div class="chat-mode">
            <div>
                <div class="mode-header">Choose your Bella mode</div>
            </div>
            <div class="mode-selection-wrapper">
                <div 
                    class="mode"
                    [ngClass]="{'selected': selectedMode === 'Solving'}"
                    (click)="handleModeSelection('Solving')"
                >
                    Solving
                </div>
                <div 
                    class="mode"
                    [ngClass]="{'selected': selectedMode === 'Listening'}"
                    (click)="handleModeSelection('Listening')"
                >
                    Listening
                </div>
                <div 
                    class="mode-info"
                    (click)="openDialog()"
                >
                    <strong>?</strong>
                </div>
            </div>
        </div>
        <div class="chat-content">
            <div *ngFor="let chat of chat().messages">
                <div *ngIf="chat.role=='user'" class="chat-bubble-user">
                    <div class="message message-user">{{chat.message}}</div>
                </div>
                <div *ngIf="chat.role=='assistant'" class="chat-bubble-bella">
                    <div class="message message-bella">{{chat.message}}</div>
                </div>
            </div>
            <div *ngIf="awaitingForResponse()" class="chat-bubble-bella">
                <div class="message message-bella"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
            </div>
        </div>
    </div>
    
    <div class="chat-footer">
        <textarea 
            id="question-box" 
            placeholder="{{placeholderText}}"
            [ngModel]="userInput"
            (ngModelChange)="handleInputChange($event)"
        >
        </textarea>
        <!-- [(ngModel)]="question" placeholder="{{displayedSampleQuestion}}"  -->
        <button 
            class="ask-btn" 
            mat-icon-button 
            (click)="handleUserInput()">
            <mat-icon>send</mat-icon> 
        </button>
    </div>
</div>


