<div mat-dialog-title class="modes-dialog-title">
  We've created 2 Bella Modes of support based on your feedback
</div>
<mat-dialog-content class="mat-typography">
  <p>
    <strong>Solving</strong> - from fertility to postpartum, supplements to partenting, Bella is trained by our experts to give you trusted guidance and advice!
  </p>

  <p>
    <strong>Listening</strong> - designed to help you work through feelings, emotions and hard times. A supportive friend you can be hnest with.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button class="modes-dialog-btn" mat-button [mat-dialog-close]="true">Ok</button>
</mat-dialog-actions>