<div mat-dialog-title class="hormonal-stage-dialog-title">
  Are you currently having menstrual periods?
</div>
<mat-dialog-content class="mat-typography">
  <form>
    <div class="radio-button-wrapper">
      <label class="radio-button-label">
        <input
          class="period-status-radio-button"
          type="radio"
          name="periodStatus"
          [value]="'yes'"
          [(ngModel)]="periodStatus"
          (ngModelChange)="handlePeriodStatusChange($event)"
        />
        <p><strong>Yes</strong>, I'm having regular periods</p>
      </label>
    </div>
    <div class="radio-button-wrapper">
      <label class="radio-button-label">
        <input
          class="period-status-radio-button"
          type="radio"
          name="periodStatus"
          [value]="'no'"
          [(ngModel)]="periodStatus"
          (ngModelChange)="handlePeriodStatusChange($event)"
        />
        <p><strong>No</strong>, I'm not having periods</p>
      </label>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <!-- <button class="modes-dialog-btn" mat-button [mat-dialog-close]="true">Ok</button> -->
</mat-dialog-actions>