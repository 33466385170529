import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition, keyframes, AnimationBuilder} from '@angular/animations';
import { MixpanelService } from 'src/app/core/services/mixpanel.service';

@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.scss'],
  animations:[
    trigger('fadeIn', [
      state('hidden', style({ opacity: 0 ,})),
      state('visible', style({ opacity: 1,})),
      transition('hidden => visible', animate('2s ease-in')),
    ])
  ]
})
export class ThankYouPageComponent implements OnInit {

  referralMessage: string = `I hope you found the answers helpful. By signing up and downloading Baby2Body app, you'll unlock access to even more detailed and comprehensive responses to all your questions.`;
  displayedReferralText: string = "";

  finishedLoading:boolean = false;

  constructor(
    private animationBuilder: AnimationBuilder,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit() {
    const builder_intro = this.animationBuilder.build(this.makeTypewriterAnimation("1s"));
    const introductionText = document.getElementById("introduction-text");
    const introAnimation = builder_intro.create(introductionText);

    introAnimation.onDone(() => {
      this.descriptionTypewriter(this.referralMessage)
      .then(() => {
        this.finishedLoading = true;
      }
      )
    });

    introAnimation.play();
    this.mixpanelService.trackEvent("Web - Bella - Thank you Page")
  }

  makeTypewriterAnimation(timeToFinishAnimation:string) {
    const typewriterAnimation = [
      animate(timeToFinishAnimation, keyframes([
        style({ width: 0, opacity: 0, whiteSpace: "nowrap"}),
        style({ width: "100%", opacity: 1})
      ]))
    ]
    return typewriterAnimation
  }

  descriptionTypewriter(message:string) {

    return new Promise<void>((resolve, reject) => {
      const speed = 30; // Adjust the speed of typing
  
      const type = async () => {
        for (let i = 0; i < message.length; i++) {
          this.displayedReferralText += message.charAt(i);
          await new Promise(resolve => setTimeout(resolve, speed));
        }
        resolve();
      };
  
      type();
    });
    
  }

}
