import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GoogleRecaptchaService {

  constructor(
    private http: HttpClient,
  ) {}

  createAssessment(token:string, expectedAction:string, siteKey:string) {
    const url = "https://o5z9y3j42k.execute-api.eu-west-1.amazonaws.com/production/google-cloud/recaptcha-assessment"
    
    const requestPayload = {
      event: {
        token:token,
        siteKey:siteKey,
        expectedAction:expectedAction
      } 
    }
    
    return this.http.post(url, requestPayload)
  }

}
