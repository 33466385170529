<div class="start-page-wrapper">
    <div class="start-page-header">
        <img class="bella-avatar" src="assets/bella/avatar-1.png">
        <div class="introduction-wrapper">
            <h1 class="b2b-pink intro-text">
                Hey, I'm Bella
            </h1>
        </div>
        <div class="description-wrapper" >
            <p class="typewriter-text"> 
                I'm your go-to, for anything related to women’s health and wellness. How can I help you today?
            </p>
        </div>
    </div>
    <div class="start-page-content">
        <h2 class="b2b-pink">
            Select your stage
        </h2>
        <div class="stage-options-wrapper">
            <div class="stage-option" (click)="handleStageSelection('ttc')">
                <div class="stage-option-icon">
                    <img class="stage-icon" src="assets/bella/ttc-icon.png">
                </div>
                <div class="stage-option-text">
                    <h4>Conceiving</h4>
                </div>
            </div>
            <div class="stage-option" (click)="handleStageSelection('pregnant')">
                <div class="stage-option-icon">
                    <img class="stage-icon" src="assets/bella/pregnant-icon.png">
                </div>
                <div class="stage-option-text">
                    <h4>Pregnant</h4>
                </div>
            </div>
            <div class="stage-option" (click)="handleStageSelection('postpartum')">
                <div class="stage-option-icon">
                    <img class="stage-icon" src="assets/bella/postpartum-icon.png">
                </div>
                <div class="stage-option-text">
                    <h4>Postpartum</h4>
                </div>
            </div>
            <div class="stage-option" (click)="openHormonalStageDialog()">
                <div class="stage-option-icon">
                    <img class="stage-icon" src="assets/bella/menopause.png">
                </div>
                <div class="stage-option-text">
                    <h4>Hormonal Health</h4>
                </div>
            </div>
        </div>
    </div>
</div>