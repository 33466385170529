import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'modes-details-dialog',
  templateUrl: './modes-details-dialog.component.html',
  styleUrls: ['./modes-details-dialog.component.scss']
})
export class ModesDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ModesDialogComponent>,
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }
}