import { Component } from '@angular/core';

@Component({
  selector: 'app-bella',
  templateUrl: './bella.component.html',
  styleUrls: ['./bella.component.scss']
})
export class BellaComponent {

}
