import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BellaRoutingModule } from './bella-routing.module';

// Components
import { StartPageComponent } from './pages/start-page/start-page.component';
import { ChatPageComponent } from './pages/chat-page/chat-page.component';
import { ThankYouPageComponent } from './pages/thank-you-page/thank-you-page.component';


// Angular Material Components
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';

// Forms Module
import { FormsModule } from '@angular/forms';
import { BellaComponent } from './components/bella/bella.component';
import { ModesDialogComponent } from './pages/chat-page/modes-dialog/modes-details-dialog.component';
import { HormonalStageDialogComponent } from './pages/start-page/hormonal-stage-dialog/hormonal-stage-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {} from '@angular/material/menu';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
  declarations: [
    StartPageComponent,
    ChatPageComponent,
    ThankYouPageComponent,
    BellaComponent,
    ModesDialogComponent,
    HormonalStageDialogComponent
  ],
  imports: [
    CommonModule, 
    BellaRoutingModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    FormsModule,
    MatDialogModule,
    MatMenuModule
  ]
})
export class BellaModule { }
