import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition, keyframes, AnimationBuilder} from '@angular/animations';
import { Router } from '@angular/router';
import { BellaService } from '../../bella.service';
import { MessageClass } from '../../models/bella.model';
import { StorageService } from 'src/app/core/services/storage.service';
import { MixpanelService } from 'src/app/core/services/mixpanel.service';
import { MatDialog } from '@angular/material/dialog';
import { HormonalStageDialogComponent } from './hormonal-stage-dialog/hormonal-stage-dialog.component';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit {

  bellaDescription: string = `I’m an AI wellness coach here to provide information and support on topics related to 
    pregnancy diet, exercise, overall wellness and helping you live as healthy as possible.
  `;
  displayedDescriptionText: string = "";

  stageSelectionText: string = `Please select your stage`;
  displayedStageText: string = "";

  dateSelectionText: string = `What's your due date?`;
  displayedDateText: string = "";
  shouldHideDateSection = true;

  stageSampleQuestions: { [key: string]: string[] } = {
    "ttc":[
      "What are some natural ways to boost fertility?",
      "What are the common signs of ovulation?",
      "How to increase the chances of getting pregnant?",
      "What are some common fertility issues and their treatments?",
      "Can you provide tips for tracking menstrual cycles for conception?"
    ],
    "pregnant":[
      "What are the early signs of pregnancy?",
      "What foods should be avoided during pregnancy?",
      "What exercises are safe during pregnancy?",
      "What are the stages of fetal development?",
      "What should I pack in my hospital bag for labor and delivery?",
    ],
    "postpartum":[
      "What are common postpartum recovery tips?",
      "How can I manage postpartum mood swings?",
      "What are some breastfeeding tips for new mothers?",
      "What exercises can help with postpartum fitness?",
      "What are the signs of postpartum depression?"
    ]
  }

  displayedSampleQuestion: string = "";

  isStageSelected: boolean = false;
  dateIsSelected: boolean = false;
  dateSectionIsHidden: boolean = false;
  stage:string = "";

  day!: string;
  month!: string; // Month index start from 0
  year: string = String(new Date().getFullYear());
  pregnancyMinDate = new Date().getFullYear();
  pregnancyMaxfutureDate = new Date().getFullYear() + 1
  dateIsValid = true;
  dateErrorMessage!: string;

  question:string = "";

  constructor(
    private router: Router,
    private bellaService: BellaService,
    private storageService: StorageService,
    private mixpanelService: MixpanelService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    // Set localstorage question counter to 0
    if(!this.storageService.check("questionsAsked")){
      this.storageService.set("questionsAsked", 0);
    }

    this.mixpanelService.trackEvent("Web - Bella - Start Page")
  }

  openHormonalStageDialog() {
    this.dialog.open(HormonalStageDialogComponent, {
      width: '350px',
      data: { message: "This is a dialog!" }
    });
  }

  hideDateSection() {
    this.shouldHideDateSection = !this.shouldHideDateSection;
  }
  
  // Handlers
  handleStageSelection(value:string) {
    this.isStageSelected = true;
    this.stage = value;
    this.bellaService.stage.set(value);
    this.router.navigateByUrl("bella/chat");
  }

  handleQuestionChange(value:string) {
    this.bellaService.question.set(value);
  }

  handleDateSubmission() {
    const year = parseInt(this.year);
    const month = parseInt(this.month) - 1; // Month is 0-indexed, so subtract 1
    const day = parseInt(this.day);
    const dueDate = new Date(year, month, day);
    
    // Get today's date
    const today = new Date();

    // Calculate the difference between the two dates in milliseconds
    const differenceInMilliseconds = dueDate.getTime() - today.getTime();

    // Convert the difference to days
    const millisecondsInOneDay = 24 * 60 * 60 * 1000;
    const differenceInDays = differenceInMilliseconds / millisecondsInOneDay;

    const daysLeft = Math.ceil(differenceInDays)
  
    const weeks = Math.floor(daysLeft / 7);
    const pregnancyWeek = 43 - weeks;

    if (month+1 > 12) {
      this.dateIsValid = false;
      this.dateErrorMessage = "Month value can not be more than 12";
    }
    else if (day > 31) {
      this.dateIsValid = false;
      this.dateErrorMessage = "Day value can not be more than 31";
    }
    else if (year > this.pregnancyMaxfutureDate) {
      this.dateIsValid = false;
      this.dateErrorMessage = "Date should be a maximum of 9 months in the future.";
    }
    else if (weeks >= 0) {
      this.dateIsValid = true;
      this.bellaService.pregnancyWeek.set(pregnancyWeek);
      this.dateIsSelected = true;
      this.router.navigateByUrl("bella/chat");
    }
    else {
      this.dateIsValid = false;
      this.dateErrorMessage = "Date should be in the future.";
    }
    
  }
  
  handleAsk() {
    this.router.navigateByUrl("bella/chat");
    this.storageService.set("stage_selected", true)
  }

  handleKeyPress(event: KeyboardEvent) {
    // Check if the key pressed is Enter (key code 13) or the Enter key in a mobile virtual keyboard
    if (event.key === "Enter") {
      this.handleAsk();
    }
  }
}
