import { Injectable, signal, WritableSignal } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MessageClass, Chat } from './models/bella.model';
import { Observable } from 'rxjs';
import { StorageService } from 'src/app/core/services/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BellaService {

  question = signal("");
  questionsAsked = signal(this.getNumberOfQuestionsAsked());
  totalQuestionsLimit = 5;
  stage = signal("");
  stageDate = signal("");
  pregnancyWeek = signal(0);
  awaitingForResponse = signal(false);
  chat: WritableSignal<Chat> = signal({conversationId:"", messages:[]});

  private responseIntervalId: any;
  bellaWebDemoBaseUrl = environment.bellaWebDemoBaseUrl;
  
  constructor(
    private http: HttpClient,
    private storageService:StorageService
  ) {}

  isStageDateRequired(): boolean {

    if (this.stage() === "pregnant" && this.pregnancyWeek() === 0) {
      return true
    }
    return false
  }

  sendChatCompletionRequest(query: string, chatMode: string = "Solution", conversationId: string|null = null, ): Observable<any> {
      let userStage = this.stage();

      // Add pregnancy week to stage if the user is pregnant to
      // generate stage related responses
      if (this.stage() == "pregnant") {
        userStage = `${this.stage()} ${this.pregnancyWeek()} Weeks`
      }

      const requestPayload :any = {
        query:query,
        user_stage:userStage
      }
      if (this.chat().conversationId !== "") {
        requestPayload["conversation_id"] = this.chat().conversationId;
      }

      if (chatMode == "Solving") {
        return this.http.post(this.bellaWebDemoBaseUrl + "chat-async", requestPayload);
      }
      else {
        return this.http.post(this.bellaWebDemoBaseUrl + "chat/emotional", requestPayload);
      }
  }

  getChatCompletionResponse(executionArn:string): Observable<any> {
    const requestPayload = {
      executionArn:executionArn
    }
    return this.http.post(this.bellaWebDemoBaseUrl + "chat-response", requestPayload);
  }

  checkResponseStatus(executionArn:string): void {
      this.responseIntervalId = setInterval(() => {
        this.getChatResponse(executionArn);
      }, 5000);
  }

  checkEmotionalResponseStatus(data:any): void {
      this.processEmotionalChatResponse(data)
  }

  private getChatResponse(executionArn:string): any {
    const requestPayload = {
      executionArn:executionArn
    }
    
    this.http.post(this.bellaWebDemoBaseUrl + "chat-response", requestPayload).subscribe({
        next: (data: any) => {
          if(data["status"] === "SUCCEEDED") {
            this.clearResponseIntervalId();
            
            let statusCode = data["result"]["status_code"];
            let responseId = data["result"]["message_id"];
            let response = data["result"]["response"]
            let conversationId = data["result"]["conversation_id"]
            
            if(statusCode == 200) {
              this.chat.mutate(chat => {
                const assistantResponse = new MessageClass(responseId, response, "assistant")
                chat.messages.push(assistantResponse)
                chat.conversationId = conversationId;
              })
              // this.increaseNumberOfQuestionsAsked();
            }
            else if (statusCode == 400) {
              this.chat.mutate(chat => {
                const assistantResponse = new MessageClass(responseId, response, "assistant")
                chat.messages.push(assistantResponse)
              })
            }
            
            this.awaitingForResponse.set(false);
          }
          else if (data["status"] === "FAILED") {
            clearInterval(this.responseIntervalId);
            this.chat.mutate(chat => {
              const assistantResponse = new MessageClass("", "Sorry, something went wrong there. Please try again and contact us if the issue persists.", "assistant")
              chat.messages.push(assistantResponse)
            })
            this.awaitingForResponse.set(false);
          }
        },
        error: response => {
          console.log(response);
        }
    });
  }

  private processEmotionalChatResponse(data:any): any {
    
    let statusCode = data["status_code"];
    let responseId = data["message_id"];
    let response = data["response"]
    let conversationId = data["conversation_id"]
    
    if(statusCode == 200) {
      this.chat.mutate(chat => {
        const assistantResponse = new MessageClass(responseId, response, "assistant")
        chat.messages.push(assistantResponse)
        chat.conversationId = conversationId;
      })
    }
    else if (statusCode == 400) {
      this.chat.mutate(chat => {
        const assistantResponse = new MessageClass(responseId, response, "assistant")
        chat.messages.push(assistantResponse)
      })
    }
    
    this.awaitingForResponse.set(false);
  }

  clearResponseIntervalId() {
    if (this.responseIntervalId) {
      clearInterval(this.responseIntervalId);
    }
  }

  getNumberOfQuestionsAsked():number {
    return Number(this.storageService.get("questionsAsked"));
  }

  increaseNumberOfQuestionsAsked() {
    let count = this.getNumberOfQuestionsAsked();
    count = count + 1;
    this.storageService.set("questionsAsked", count);
    this.questionsAsked.set(count);
  }
}
