export class MessageClass {
    public id: string;
    public message: string;
    public role: string;

  constructor(id: string, message: string, role: string) {
    this.id = id;
    this.message = message;
    this.role = role
  }

}

export interface Chat {
  conversationId: string;
  messages: MessageClass[];
}
