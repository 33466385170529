import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleRecaptchaService } from 'src/app/core/services/google-recaptcha.service';
import { MixpanelService } from 'src/app/core/services/mixpanel.service';
import { environment } from 'src/environments/environment';
import { BellaService } from '../../bella.service';
import { MessageClass } from '../../models/bella.model';
import { MatDialog } from '@angular/material/dialog';
import { ModesDialogComponent } from './modes-dialog/modes-details-dialog.component';

declare const grecaptcha: any; // Declare grecaptcha variable to avoid TypeScript errors

@Component({
  selector: 'app-chat-page',
  templateUrl: './chat-page.component.html',
  styleUrls: ['./chat-page.component.scss'],
})
export class ChatPageComponent implements OnInit{

  question = this.bellaService.question;
  questionsAsked = this.bellaService.questionsAsked;
  totalQuestionsLimit = 5;
  selectedMode = "Solving";

  placeholderText = "Enter your question here..."
  awaitingForResponse = this.bellaService.awaitingForResponse;
  userInput: string = "";
  stage = this.bellaService.stage;
  chat = this.bellaService.chat;

  pregnancyMaxfutureDate = new Date().getFullYear() + 1

  constructor(
    private router: Router,
    private bellaService: BellaService,
    private mixpanelService: MixpanelService,
    private googleRecaptchaService: GoogleRecaptchaService,
    private dialog: MatDialog
  ){}

  ngOnInit() {
    // console.log(` Question is => ${this.question()} and user stage is ${this.stage()}`)
    this.userInput = this.question();

    if (this.bellaService.stage() == "") {
      // Redirect users to the start page to capture their stage correctly.
      this.router.navigateByUrl("bella/start");
    }

    this.displayWelcomeMessage(this.selectedMode);
    
    this.mixpanelService.trackEvent("Web - Bella - Chat Page")
  }

  openDialog() {
    this.dialog.open(ModesDialogComponent, {
      width: '350px',
      data: { message: "This is a dialog!" }
    });
  }

  handleInputChange(value:string) {
    this.userInput = value;
  }

  handleModeSelection(selectedMode:string) {
    // Clear chat history
    // Add Bella message based on Mode
    this.selectedMode = selectedMode;
    this.displayWelcomeMessage(selectedMode);
  }

  displayWelcomeMessage(bellaMode:string) {
    this.bellaService.clearResponseIntervalId();
    var selectedMessage = "";
    if (this.bellaService.isStageDateRequired()) {
      this.chat.mutate(chat => {
        selectedMessage = "To personalize your experience, please enter your due date in the following format: \n YYYY-MM-DD (e.g., 2024-10-31)", "assistant";
        this.placeholderText = "What's your due date?"
      })
    } else {
        if (bellaMode === "Solving") {
          selectedMessage = "I'm ready to answer your questions. Ask me anything!", "assistant";
          this.placeholderText = "Enter your question here..."
        } 
        else if (bellaMode === "Listening") {
          selectedMessage = "I'm all ears. What's on your mind?", "assistant";
          this.placeholderText = "Share your thoughts..."
        }
    }
    this.chat.mutate(chat => {
      const message = new MessageClass("", selectedMessage, "assistant")
      chat.messages = []
      chat.conversationId = "";
      chat.messages.push(message);
    });

    // Reset userInput
    this.userInput = "";
    // Reset loading chat if any
    this.awaitingForResponse.set(false);
  }

  handleDateSubmission(dateString:string) {

    var dateMessage = ""
    dateString = dateString.trim();

    // Regular expression to check if the date string is in the correct format YYYY-MM-DD
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;

    // Validate the date string format
    if (!datePattern.test(dateString)) {
        dateMessage = "Invalid date format. Expected format is YYYY-MM-DD.";
        this.chat.mutate(chat => {
          const message = new MessageClass("", dateMessage , "assistant")
          chat.messages.push(message);
        })
        this.awaitingForResponse.set(false);
        return;
    }

    // Split the date string by the hyphen
    const dateParts = dateString.split('-');

    // Extract the year, month, and day as integers
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10);
    const day = parseInt(dateParts[2], 10);

    const dueDate = new Date(year, month, day);
    
    // Get today's date
    const today = new Date();

    // Calculate the difference between the two dates in milliseconds
    const differenceInMilliseconds = dueDate.getTime() - today.getTime();

    // Convert the difference to days
    const millisecondsInOneDay = 24 * 60 * 60 * 1000;
    const differenceInDays = differenceInMilliseconds / millisecondsInOneDay;

    const daysLeft = Math.ceil(differenceInDays)
  
    const weeks = Math.floor(daysLeft / 7);
    const pregnancyWeek = 43 - weeks;

    // Check if the date is more than 9 months in the future
    const inputDate = new Date(year, month - 1, day);
    const currentDate = new Date();
    const maxFutureDate = new Date(currentDate);
    maxFutureDate.setMonth(currentDate.getMonth() + 9);

    if (month > 12) {
      dateMessage = "Month value can not be more than 12";
    }
    else if (day > 31) {
      dateMessage = "Day value can not be more than 31";
    }
    else if (inputDate > maxFutureDate) {
      dateMessage = "Date should be a maximum of 9 months in the future.";
    }
    else if (weeks >= 0) {
      this.bellaService.pregnancyWeek.set(pregnancyWeek);
      if (this.selectedMode === "Solving") {
        dateMessage = "I'm ready to answer your questions. Ask me anything!";
        this.placeholderText = "Enter your question here...";
      } 
      else if (this.selectedMode === "Listening") {
        dateMessage = "I'm all ears. What's on your mind?";
        this.placeholderText = "Share your thoughts...";
      }
      // Reset userInput
      this.userInput = "";
    }
    else {
      dateMessage = "Date should be in the future.";
    }
    this.chat.mutate(chat => {
      const message = new MessageClass("", dateMessage , "assistant")
      chat.messages.push(message);
    })
    this.awaitingForResponse.set(false);
  }

  handleUserInput() {
    if (this.userInput != "") {
        // Handle Data Collection
        if (this.bellaService.isStageDateRequired()) {
          const dateString = this.userInput;

          this.chat.mutate(chat => {
              const message = new MessageClass("", dateString, "user")
              chat.messages.push(message);
          })
          this.awaitingForResponse.set(true);
          this.handleDateSubmission(dateString)
        }
        // Asking question
        else {
          this.question.set(this.userInput);
          this.userInput = "";
          
          this.chat.mutate(chat => {
              const message = new MessageClass("", this.bellaService.question(), "user")
              chat.messages.push(message);
          })
          
          this.awaitingForResponse.set(true);
          grecaptcha.enterprise.ready(async () => {
              const token = await grecaptcha.enterprise.execute(environment.googleRecaptchaSiteID, {action: 'BELLA_ASK_QUESTION'});
              this.googleRecaptchaService.createAssessment(token, "BELLA_ASK_QUESTION", environment.googleRecaptchaSiteID)
                  .subscribe({
                      next: (assessmentResponse: any) => {
                          // Once createAssessment response is returned, call sendChatCompletionRequest
                          if (assessmentResponse.score > 0.5) {
                            if (this.selectedMode == "Listening") {
                              this.bellaService.sendChatCompletionRequest(this.question(), this.selectedMode).subscribe({
                                next: data => {
                                    this.bellaService.checkEmotionalResponseStatus(data);
                                },
                                error: response => {
                                    console.log(response)
                                }
                              });
                            }
                            else {
                              this.bellaService.sendChatCompletionRequest(this.question(), this.selectedMode).subscribe({
                                next: data => {
                                    // Once the completion request is made sucessfully, call the get response endpoint
                                    // every 5 seconds and check if the generated answer is ready
                                    const executionArn = data["executionArn"];
                                    this.bellaService.checkResponseStatus(executionArn);
                                },
                                error: response => {
                                    console.log(response)
                                }
                              });
                            }
                          }
                          else {
                            this.chat.mutate(chat => {
                              const message = new MessageClass("", "Oops! It seems there's unusual activity from your browser. For security reasons, we can't process your request. Please reach out to our support team if you have any concerns.", "assistant")
                              chat.messages.push(message);
                            })
                            this.awaitingForResponse.set(false);
                          }
                      },
                      error: (error: any) => {
                          console.error(error);
                      }
                  });
          });
        }
        
    }
  }

  handleNewChat() {
    this.displayWelcomeMessage(this.selectedMode);
  }
}
