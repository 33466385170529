import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BellaService } from '../../../bella.service';

@Component({
  selector: 'hormonal-stage-dialog',
  templateUrl: './hormonal-stage-dialog.component.html',
  styleUrls: ['./hormonal-stage-dialog.component.scss']
})
export class HormonalStageDialogComponent {

  periodStatus!: string;

  constructor(
    public dialogRef: MatDialogRef<HormonalStageDialogComponent>,
    private router: Router,
    private bellaService: BellaService
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  handlePeriodStatusChange(selectedValue: string) {
    this.periodStatus = selectedValue;

    // User is having regular periods
    if (this.periodStatus === "yes") {
      this.bellaService.stage.set("perimenopause");
    }
    // User not having periods
    else if (this.periodStatus === "no") {
      this.bellaService.stage.set("menopause");
    }

    this.onClose();
    this.router.navigateByUrl("bella/chat");
  }
}